<template>
  <div class="content">
    <el-card shadow="never" class="new-card" style="height: 100%">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">导出列表</div>
        </div>
        <div class="card-header-left">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="下载状态">
              <el-select
                v-model="formInline.downloadState"
                placeholder="下载状态："
                clearable=""
              >
                <el-option label="下载完成" :value="2"></el-option>
                <el-option label="下载中" :value="1"></el-option>
                <el-option label="下载失败" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getUserList" size="small"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div :style="{ overflowY: 'auto', height: tableHeight }">
        <el-table :data="tableData" border>
          <el-table-column label="ID" prop="id" align="center" />
          <el-table-column label="文件名称" align="center" prop="excelName">
          </el-table-column>
          <el-table-column label="下载状态" align="center">
            <template slot-scope="scope">
              <span class="success" v-if="scope.row.downloadState == 2">
                下载完成
              </span>
              <span class="warning" v-if="scope.row.downloadState == 1">
                下载中
              </span>
              <span class="error" v-if="scope.row.downloadState == 3">
                下载失败
              </span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime">
          </el-table-column>
          <el-table-column label="操作" align="center" width="170">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handle(scope.row.excelUrl)"
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div
          style="
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            margin-bottom: 30px;
          "
        >
          <el-pagination
            :current-page="page.current"
            :page-sizes="page.sizes"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      tableData: [], // 表格数据
      formInline: {
        downloadState: "",
        current: 1,
        size: 10,
      },
      page: {
        current: 1, // 当前在第几页
        sizes: [10, 20, 50, 100], // 每页显示多少条选择数组
        size: 10, // 每页显示多少条
        total: 0, // 共几条
      },
      tableHeight: "850px",
    };
  },
  created() {
    this.getUserList();
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 210 + "px";
  },
  methods: {
    init() {},

    // 获取用户列表
    getUserList() {
      this.axios
        .get("CUSTOMERANALYSIS_DOWNLOAD", this.formInline)
        .then((result) => {
          console.log("用户列表", result);
          // this.tableData = result.data;
          this.tableData = result.data.records;
          this.page.total = result.data.total;
        });
    },
    handle(url) {
      if (url) {
        let str = url.replace(/http/, "https");
        window.location.href = str;
      } else {
        this.$message.error("下载链接不存在！");
      }
    },
    // 每页多少条
    handleSizeChange(val) {
      this.formInline.size = val;
      this.getUserList();
    },
    // 切换到第几页
    handleCurrentChange(val) {
      this.formInline.current = val;
      this.getUserList();
    },
  },
};
</script>

<style>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 20px;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
  .success {
    color: #67c23a;
  }
  .warning {
    color: #e6a23c;
  }
  .error {
    color: #f56c6c;
  }
}
</style>
